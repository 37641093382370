<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el patrono:</h6>
            </template>
            <b-row>
              <!-- FIRST LINE -->
              <b-col md="3" cols="12">
                <TextComponent :form="form" name="policy" label="Póliza No" disabled />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="certificateNumber"
                  label="Certificado No"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="company" label="Compañía" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="salary" label="Salario" disabled />
              </b-col>

              <!-- SECOND LINE -->

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="employeeNumber" label="Nº Empleado" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="ocupacity" label="Ocupación" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="plan" label="Plan/Clase" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="costCenter" label="Centro de Costo" disabled />
              </b-col>
            </b-row>
            <!-- THIRD LINE -->
            <h5>FECHA EN QUE EL COLABORADOR</h5>
            <b-row>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="companyDateFromText"
                  label="Entra en servicio en la compañia"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="serviceDateFromText"
                  label="Entra al servicio permanente"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="policyDateFromText"
                  label="Se incluye en la póliza"
                  disabled
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="effectiveDateText"
                  label="Fecha Efectiva"
                  disabled
                />
              </b-col>
              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="observations"
                  label="Observaciones"
                  rules="max:40"
                  disabled
                />
              </b-col>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Firmar</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import TextComponent from "@/components/controls/TextComponent.vue";
import { saltarBeneficiarios, saltarDependientes } from "../../../helpers/reglasCostaRica";

export default {
  name: "Home",
  components: {
    TextComponent,
  },
  data: function () {
    return {
      messagge: "",
      control: { LanguageDisabled: 1 },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    const { form } = this.$store.state.wizard;

    if (form === undefined || form === null || form === "") {
      this.$router.push("/form/costarica/");
      return;
    }

    this.form = form;

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      const saltar_dependientes = saltarDependientes(this.form.observations);

      let nextForm = 3;

      if (saltar_dependientes) {
        nextForm = 2;
        if (saltar_beneficiarios) {
          nextForm = 1;
        }
      }

      this.$store
        .dispatch("wizard/save", { index: nextForm - 1, form })
        .then(this.$router.push(`/form/costarica-${nextForm}.html`));
    },
    next() {
      this.$store
        .dispatch("wizard/save", this.form)
        .then(this.$router.push("/form/costarica-5.html"));
    },
    formatter(date) {
      if (date && date != "") {
        const y = date.substring(0, 4);
        const m = date.substring(6, 7);
        const d = date.substring(9, 10);

        return `${d}/${m}/${y}`;
      } else return "";
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}
</style>
