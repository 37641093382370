export function conyugeRepetido(window) {
  // ATENCION CODIGO DE CONYUGE PUEDE SER "C" O "CY"
  const conyuges = window.form.beneficiarios.filter(
    (e) => e.relation && e.relation.trim().substring(0, 1) == "C"
  );

  if (conyuges.length > 1) {
    window.$bvToast.toast("No puede haber dos beneficiarios con relación de Esposo(a) / Conyuge", {
      title: "Información incorrecta",
      variant: "danger",
      solid: true,
    });
    return true;
  } else {
    return false;
  }
}

export function documentosDuplicados(window) {
  const documents = window.form.beneficiarios
    .filter((e) => e.document && e.document.trim() != "")
    .map((m) => {
      return m.document;
    });

  const duplicados = new Set(documents).size != documents.length;

  if (duplicados) {
    window.$bvToast.toast("No puede haber Nro de Identificación duplicados en los beneficiarios", {
      title: "Información incorrecta",
      variant: "danger",
      solid: true,
    });
    return true;
  } else {
    return false;
  }
}
