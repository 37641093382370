<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">
                Para Dependientes Elegibles / Beneficios (si desea cobertura para ellos)
              </h6>
            </template>
            <div class="table-responsive">
              <table class="table-one">
                <tr>
                  <!-- Headers-->
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Número de Identidad</th>
                  <th>Fecha Nacimiento (mm/dd/aaaa)</th>
                  <th>Estatura (mts)</th>
                  <th>Peso (lbs)</th>
                  <th>Sexo</th>
                  <th>Parentesco</th>
                </tr>
                <tr v-for="(item, index) in form.dependientes" v-bind:key="index">
                  <!-- Rows-->
                  <td style="width: 15%">
                    <b-form-input
                      type="text"
                      v-model="item.name"
                      :state="getValidationName(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 15%">
                    <b-form-input
                      type="text"
                      v-model="item.lastName"
                      :state="getValidationLastName(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 7%">
                    <b-form-input
                      type="text"
                      v-model="item.document"
                      :state="getValidationDocument(index)"
                      rules="max:10"
                    ></b-form-input>
                  </td>

                  <td style="width: 5%">
                    <DateGrid
                      :form="item"
                      name="birthDay"
                      :state="getValidationBirthDay(index)"
                    ></DateGrid>
                  </td>

                  <td style="width: 7%">
                    <b-form-input
                      type="number"
                      v-model="item.stature"
                      step="0.01"
                      min="1"
                      max="2.5"
                      :state="getValidationStature(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 6%">
                    <b-form-input
                      type="number"
                      v-model="item.weight"
                      :state="getValidationWeight(index)"
                    ></b-form-input>
                  </td>

                  <td style="width: 7%">
                    <validation-provider name="label">
                      <b-form-select
                        v-model="item.sex"
                        :options="control.TIPOS_SEXO"
                        :state="getValidationSex(index)"
                      ></b-form-select>
                    </validation-provider>
                  </td>

                  <td style="width: 7%">
                    <b-form-select
                      v-model="item.relation"
                      :options="control.TIPOS_DEPENDIENTES"
                      :state="getValidationRelation(index)"
                    ></b-form-select>
                  </td>

                  <!-- BUTTONS -->
                  <td style="width: 2%">
                    <b-btn
                      v-if="!rowEmpty(index)"
                      pill
                      variant="outline-danger"
                      @click="limpiar(index)"
                      >Borrar</b-btn
                    >
                  </td>
                </tr>
              </table>
            </div>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import DateGrid from "../../../components/controls/DateGrid.vue";
import {
  TIPOS_DEPENDIENTES,
  TIPOS_ESTADOCIVIL,
  TIPOS_ESTATURA_UNIDAD,
  TIPOS_PESO_UNIDAD,
  TIPOS_SEXO,
} from "../../../data/ecuador/Selects";
import { conyugeRepetido, documentosDuplicados } from "../../../helpers/dependientesHelper";
import { saltarBeneficiarios } from "../../../helpers/reglasEcuador";

export default {
  name: "Home",
  components: { DateGrid },
  data: function () {
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        TIPOS_ESTADOCIVIL,
        TIPOS_DEPENDIENTES,
        TIPOS_SEXO,
        TIPOS_ESTATURA_UNIDAD,
        TIPOS_PESO_UNIDAD,
      },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }
    this.form = form;

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    previous() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      if (saltar_beneficiarios === true)
        this.$store
          .dispatch("wizard/save", { index: 0, form })
          .then(this.$router.push("/form/ecuador-1.html"));
      else
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/ecuador-2.html"));
    },
    next() {
      this.form.dependientes = this.form.dependientes.map((element) => {
        if (element.birthDay) {
          var years = moment().diff(element.birthDay, "years", false);
          return { ...element, age: years };
        } else {
          return element;
        }
      });

      if (this.validation()) {
        this.$store
          .dispatch("wizard/save", this.form)
          .then(this.$router.push("/form/ecuador-4.html"));
      }
    },
    rowEmpty(index) {
      return (
        this.form.dependientes[index].name == "" &&
        this.form.dependientes[index].lastName == "" &&
        this.form.dependientes[index].document == "" &&
        this.form.dependientes[index].relation == "" &&
        this.form.dependientes[index].sex == "" &&
        this.form.dependientes[index].birthDay == "" &&
        this.form.dependientes[index].weight == "" &&
        this.form.dependientes[index].stature == ""
      );
    },
    limpiar(index) {
      this.form.dependientes[index].name = "";
      this.form.dependientes[index].lastName = "";
      this.form.dependientes[index].document = "";
      this.form.dependientes[index].relation = "";
      this.form.dependientes[index].sex = "";
      this.form.dependientes[index].birthDay = "";
      this.form.dependientes[index].weight = "";
      this.form.dependientes[index].stature = "";
      this.form.dependientes[index].age = "";
    },
    getValidationName(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].name != "";

      if (state && this.form.dependientes[index].name == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationLastName(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].lastName != "";

      if (state && this.form.dependientes[index].lastName == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationDocument(index) {
      const state =
        (this.rowEmpty(index) || this.form.dependientes[index].document != "") &&
        this.form.dependientes[index].document.length <= 10;

      if (state && this.form.dependientes[index].document == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationRelation(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].relation != "";

      if (state && this.form.dependientes[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationBirthDay(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].birthDay != "";

      if (state && this.form.dependientes[index].birthDay == "") {
        return null;
      } else {
        return state;
      }
    },

    // eslint-disable-next-line no-unused-vars
    getValidationSex(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].sex != "";

      if (state && this.form.dependientes[index].sex == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationWeight(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].weight != "";

      if (state && this.form.dependientes[index].weight == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationStature(index) {
      const state = this.rowEmpty(index) || this.form.dependientes[index].stature != "";

      if (state && this.form.dependientes[index].stature == "") {
        return null;
      } else {
        return state;
      }
    },

    validation() {
      let ok = true;

      if (conyugeRepetido(this)) {
        return false;
      }

      if (documentosDuplicados(this)) {
        return false;
      }

      for (let i = 0; i < this.form.dependientes.length; i++) {
        if (!this.rowEmpty(i)) {
          ok =
            ok &&
            this.getValidationName(i) &&
            this.getValidationLastName(i) &&
            this.getValidationDocument(i) &&
            this.getValidationRelation(i) &&
            this.getValidationSex(i) &&
            this.getValidationBirthDay(i) &&
            this.getValidationStature(i) &&
            this.getValidationWeight(i);
        }
      }

      return ok;
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 100% !important;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
