<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el Empleado:</h6>
            </template>
            <b-row>
              <b-col md="9" cols="12">
                <CheckComponent
                  :form="form"
                  name="planMultiple"
                  rules="required"
                  :options="control.TIPOS_PLAN_GENERAL"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row>
              <!-- FIRST LINE -->
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="firstName"
                  label="Primer Nombre"
                  rules="required"
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="secondFirstName" label="Segundo Nombre" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="lastName" label="Apellidos" rules="required" />
              </b-col>

              <b-col md="3" cols="12">
                <SelectComponent
                  :form="form"
                  name="identificationType"
                  label="Cédula de Identidad"
                  rules="required"
                  :options="control.TIPOS_IDENTIFICACIONES"
                />
              </b-col>

              <!-- SECOND LINE -->

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="identification"
                  label="Número de Identificación"
                  rules="required"
                  disabled
                />
              </b-col>

              <!-- FOURTH LINE -->

              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="adress"
                  label="Dirección"
                  rules="required|max:60"
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="adress2" label="Dirección 2" rules="max:30" />
              </b-col>

              <!-- FIFTH LINE -->

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="state" label="Barrio o Colonia" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="city" label="Ciudad" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="Country" label="Pais" rules="required" />
              </b-col>

              <b-col md="3" cols="12">
                <DateComponent
                  :form="form"
                  name="birthDay"
                  label="Fecha de Nacimiento (mm/dd/aaaa)"
                  rules="required|MMDDYYYY"
                  disabled
                />
              </b-col>

              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="email"
                  label="Correo Electrónico"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="phone" label="Teléfono" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="countryNatal"
                  label="Lugar de nacimiento (Ciudad/País)"
                  rules="required"
                />
              </b-col>

              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="sex"
                  label="Sexo"
                  rules="required"
                  :options="control.TIPOS_SEXO"
                />
              </b-col>

              <b-col md="2" cols="12">
                <TextComponent
                  :form="form"
                  name="weight"
                  type="number"
                  label="Peso"
                  rules="required"
                  :min="form.weightUnit == 'K' ? 30 : 66"
                  :max="form.weightUnit == 'K' ? 300 : 660"
                />
              </b-col>

              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="weightUnit"
                  label="Peso Unidad"
                  rules="required"
                  :options="control.TIPOS_PESO_UNIDAD"
                />
              </b-col>

              <b-col md="2" cols="12">
                <TextComponent
                  :form="form"
                  name="stature"
                  type="number"
                  label="Estatura"
                  step="0.01"
                  :min="form.statureUnit == 'M' ? 1 : 40"
                  :max="form.statureUnit == 'M' ? 2.5 : 98"
                  rules="required"
                />
              </b-col>

              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="statureUnit"
                  label="Estatura Unidad"
                  rules="required"
                  :options="control.TIPOS_ESTATURA_UNIDAD"
                />
              </b-col>

              <b-col md="2" cols="12">
                <SelectComponent
                  :form="form"
                  name="maritalStatus"
                  label="Estado Civil"
                  rules="required"
                  :options="control.TIPOS_ESTADOCIVIL"
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="groupNumber"
                  label="Numero de Grupo"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="employeeDate" label="Fecha de Empleo" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="companyName"
                  label="Nombre del Contratante"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12" v-if="form.planMultiple && form.planMultiple.includes('V')">
                <TextComponent :form="form" name="salary" type="number" label="Salario" disabled />
              </b-col>

              <!-- SEVENTH LINE -->

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="bank" label="Banco" rules="required" />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  type="number"
                  name="bankAcount"
                  label="Nro Cuenta"
                  rules="required|min:7|max:20|Digit"
                  placeholder="Solo se deben ingresen dígitos"
                />
              </b-col>

              <b-col md="3" cols="12">
                <RadioComponent
                  :form="form"
                  name="acountType"
                  label="Tipo de cuenta"
                  rules="required"
                  :options="control.TIPOS_CUENTASBANCARIAS"
                />
              </b-col>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import CheckComponent from "@/components/controls/CheckComponent.vue";
import DateComponent from "@/components/controls/DateComponent.vue";
import RadioComponent from "@/components/controls/RadioComponent.vue";
import SelectComponent from "@/components/controls/SelectComponent.vue";
import TextComponent from "@/components/controls/TextComponent.vue";

import {
  TIPOS_CUENTASBANCARIAS,
  TIPOS_ESTADOCIVIL,
  TIPOS_ESTATURA_UNIDAD,
  TIPOS_IDENTIFICACIONES,
  TIPOS_PESO_UNIDAD,
  TIPOS_PLAN_GENERAL,
  TIPOS_SEXO,
} from "../../../data/honduras/Selects";
import { planType, saltarBeneficiarios } from "../../../helpers/reglasHonduras";

export default {
  name: "Home",
  components: {
    TextComponent,
    RadioComponent,
    SelectComponent,
    CheckComponent,
    DateComponent,
  },
  data: function () {
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        TIPOS_ESTADOCIVIL,
        TIPOS_SEXO,
        TIPOS_PLAN_GENERAL,
        TIPOS_IDENTIFICACIONES,
        TIPOS_ESTATURA_UNIDAD,
        TIPOS_PESO_UNIDAD,
        TIPOS_CUENTASBANCARIAS,
      },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    const { form } = this.$store.state.wizard;

    if (form === undefined || form === null || form === "") {
      this.$router.push("/form/honduras/");
      return;
    }

    this.form = form;

    this.form.planMultiple = planType(this.form.observations);

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    next() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      if (saltar_beneficiarios === true) {
        this.$store
          .dispatch("wizard/save", { index: 2, form })
          .then(this.$router.push("/form/honduras-3.html"));
      } else {
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/honduras-2.html"));
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}
</style>
