export function saltarBeneficiarios(observations) {
  return observations && (observations.includes("HN04") || observations.includes("HN05"));
}

export function saltarDependientes(observations) {
  return (
    observations &&
    (observations.includes("HN01") ||
      observations.includes("HN06") ||
      observations.includes("HN07"))
  );
}

export function planType(observations) {
  if (observations.includes("HN01")) {
    return ["V"];
  }
  if (observations.includes("HN02")) {
    return ["V", "G"];
  }
  if (observations.includes("HN03")) {
    return ["V", "G", "D"];
  }
  if (observations.includes("HN04")) {
    return ["G"];
  }
  if (observations.includes("HN05")) {
    return ["G", "D"];
  }
  if (observations.includes("HN06")) {
    return ["V", "A"];
  }
  if (observations.includes("HN07")) {
    return ["A"];
  }
}
