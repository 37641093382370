<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el Empleado:</h6>
            </template>
            <b-row>
              <b-col md="9" cols="12">
                <CheckComponent
                  :form="form"
                  name="planMultiple"
                  rules="required"
                  :options="control.TIPOS_PLAN_GENERAL"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row>
              <!-- FIRST LINE -->
              <b-col md="3" cols="12">
                <TextComponent :form="form" name="lastName" label="Primer Apellido" disabled />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="secondLastName"
                  label="Segundo Apellido"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="firstName" label="Primer Nombre" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="secondFirstName" label="Segundo Nombre" />
              </b-col>

              <!-- SECOND LINE -->
              <b-col md="2" cols="12">
                <TextComponent
                  :form="form"
                  name="identification"
                  label="Número de Identificación"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="2" cols="12">
                <DateComponent
                  :form="form"
                  name="birthDay"
                  label="Fch Nacimiento (mm/dd/aaaa)"
                  rules="required|MMDDYYYY"
                />
              </b-col>

              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="sex"
                  label="Sexo"
                  rules="required"
                  :options="control.TIPOS_SEXO"
                />
              </b-col>

              <b-col md="4" cols="12">
                <RadioComponent
                  :form="form"
                  name="maritalStatus"
                  label="Estado Civil"
                  rules="required"
                  :options="control.TIPOS_ESTADOCIVIL"
                />
              </b-col>
              <b-col md="2" cols="12">
                <TextComponent
                  :form="form"
                  name="maritalStatusOther"
                  label="Otro"
                  v-if="form.maritalStatus == 'Otro'"
                  v-model="form"
                />
              </b-col>

              <!-- THIRD LINE -->

              <b-col md="6" cols="12">
                <TextComponent :form="form" name="adress" label="Dirección" />
              </b-col>

              <!-- FOURTH LINE -->

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="city" label="Ciudad" />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent :form="form" name="residenceTime" label="Tiempo de Residencia" />
              </b-col>

              <!-- FIVETH LINE -->

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="employeeDate" label="Fecha de Empleo" disabled />
              </b-col>

              <b-col md="2" cols="12">
                <TextComponent :form="form" name="salary" type="number" label="Salario" disabled />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="ocupacity" label="Ocupación" disabled />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent
                  :form="form"
                  name="phone"
                  label="Teléfono Convencional"
                  rules="min:8|max:10"
                />
              </b-col>

              <b-col md="2" cols="12">
                <TextComponent
                  :form="form"
                  name="stature"
                  type="number"
                  label="Estatura (mts)"
                  step="0.01"
                  :min="1"
                  :max="2.5"
                  rules="required"
                />
              </b-col>

              <b-col md="2" cols="12">
                <TextComponent
                  :form="form"
                  name="weight"
                  type="number"
                  label="Peso (lbs)"
                  rules="required"
                  :min="66"
                  :max="660"
                />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent :form="form" name="PhoneOffice" label="Teléfono Célular" />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent
                  :form="form"
                  name="email"
                  label="Correo Electrónico"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12" v-if="form.planMultiple">
                <TextComponent :form="form" name="bank" label="Banco" rules="required" />
              </b-col>

              <b-col md="3" cols="12" v-if="form.planMultiple">
                <TextComponent
                  :form="form"
                  type="number"
                  name="bankAcount"
                  label="Nro Cuenta"
                  rules="required|min:7|max:14|numeric"
                  placeholder="Solo se deben ingresen dígitos"
                />
              </b-col>

              <b-col md="3" cols="12" v-if="form.planMultiple">
                <RadioComponent
                  :form="form"
                  name="acountType"
                  label="Tipo de cuenta"
                  rules="required"
                  :options="control.TIPOS_CUENTASBANCARIAS"
                />
              </b-col>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import CheckComponent from "@/components/controls/CheckComponent.vue";
import DateComponent from "@/components/controls/DateComponent.vue";
import RadioComponent from "@/components/controls/RadioComponent.vue";
import TextComponent from "@/components/controls/TextComponent.vue";
import { getAge } from "../../../helpers/dateHelper";
import { planType, saltarBeneficiarios } from "../../../helpers/reglasEcuador";

import {
  TIPOS_CUENTASBANCARIAS,
  TIPOS_ESTADOCIVIL,
  TIPOS_IDENTIFICACIONES,
  TIPOS_PLAN_GENERAL,
  TIPOS_SALARIO,
  TIPOS_SEXO,
  TIPOS_SINO,
} from "../../../data/ecuador/Selects";

const edadMaxima = 99;

export default {
  name: "Home",
  components: {
    DateComponent,
    TextComponent,
    RadioComponent,
    CheckComponent,
  },
  data: function () {
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        IdentificationsType: TIPOS_IDENTIFICACIONES,
        TIPOS_ESTADOCIVIL,
        TIPOS_SALARIO,
        TIPOS_SEXO,
        TIPOS_SINO,
        TIPOS_CUENTASBANCARIAS,
        TIPOS_PLAN_GENERAL,
      },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    const { form } = this.$store.state.wizard;

    if (form === undefined || form === null || form === "") {
      this.$router.push("/form/ecuador/");
      return;
    }

    this.form = form;
    this.form.planMultiple = planType(this.form.observations);

    this.$store.dispatch("app/loading", false);
  },

  methods: {
    next() {
      const form = { ...this.form };

      const edad = getAge(form.birthDay, "MM/DD/YYYY");

      if (edad > edadMaxima) {
        this.$bvToast.toast(
          "La fecha de nacimiento no puede ser mayor a " + edadMaxima + " años.",
          {
            title: "Problemas en validación",
            variant: "error",
            solid: true,
          }
        );
        return;
      }

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      if (saltar_beneficiarios === true) {
        this.$store
          .dispatch("wizard/save", { index: 2, form })
          .then(this.$router.push("/form/ecuador-3.html"));
      } else {
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/ecuador-2.html"));
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}
</style>
